import React from 'react';
import './App.scss';
import LandingPage from "./containers/landingPage";

function App() {
  return (
    <LandingPage/>
  );
}

export default App;
